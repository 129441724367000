/**
 * Configuration by exports values per environment, as set by REACT_APP_STAGE
 */
import { isArray, union } from "lodash";
import { loadClientConfig } from "up-form";
const lodash = require("lodash");
export const { name, version } = require("../package.json");
export const stage = process.env.REACT_APP_STAGE || "unknown";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const publicUrl = process.env.PUBLIC_URL || window.location.origin;
export const clientConfigKey = `config:${name}:${version}`;
// Allow build-time override from variable
const upApiOverride = process.env.REACT_APP_UP_API_URI;

export const hostMap = {
  // Journey key found from host RE match, each journey has provider and extra config groups to merge in
  "soe-nz": {
    match: /apply(-stripebillingpoc)?(-\w+)?\.soe\.ac\.nz(:\d+)?/,
    provider: "soe",
    groups: ["new-zealand"]
  },
  "soe-au": {
    match: /apply(-stripebillingpoc)?(-\w+)?\.soe\.edu\.au(:\d+)?/,
    provider: "soe",
    groups: ["australia"]
  },
  "up-online": {
    match: /(\w+\.)?uponline\.up\.education(:\d+)?/,
    provider: "up-online"
  },
  "iahealth": {
    match: /apply(-stripebillingpoc)?(-\w+)?\.iah\.edu\.au(:\d+)?/,
    provider: "iahealth",
    groups: ["australia"]
  },
  "colab": {
    match: /apply(-stripebillingpoc)?(-\w+)?\.colab\.edu\.au(:\d+)?/,
    provider: "colab",
    groups: ["australia"]
  }
};

const data = {
  // Use these values if not provided by stage-specific values
  default: {
    analytics: {
      appName: process.env.REACT_APP_APP_NAME,
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
      trackEvents: {
        blacklist: /@@redux-form\/(TOUCH|UNTOUCH|FOCUS|BLUR)/ // remove some of the highest volume events
      }
    },
    global: {
      buildId,
      branch,
      stage,
      commit,
      isDev,
      googleAnalytics: {
        googleAnalyticsId: "GTM-NK4GLDZ"
      },
      links: {
        home: "https://up.education", // site developer home (not provider)
        publicUrl,
        paymentApproved: `${publicUrl}/payment/approved`,
        paymentDeclined: `${publicUrl}/payment/declined`,
        paymentCancelled: `${publicUrl}/payment/cancelled`,
        zipRedirectUrl: `${publicUrl}/zipCheckout`
      },
      component: {
        isZipEnabled: false,
        storeCardDetails: false,
        checkFeesFree: true,
        addressAutoComplete: true,
        clearAfterSubmit: true,
        Checkout: {
          noAlternativePaymentMethod: true,
          hideTotalAndDiscount: false
        },
        SignUp: {
          validatePhoneNumber: true
        },
        Complete: {
          showCompletePageImage: true
        },
        documents: {
          accept: [
            ".doc",
            ".docx",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pdf",
            "application/pdf",
            ".png",
            ".jpg",
            ".jpeg",
            "image/*"
          ],
          maxFileSize: "32Mb"
        },
        persist: false
      },
      google: {
        apiKey: "AIzaSyBdS0kbi8NqHmmUcfFRrNyXgD85ikNuQvo"
      },
      stripe: {
        publicToken:
          "pk_test_51ICGIGIU968z20uKn3plzqIEvcH3RqIXMowWiu0kzpFaFNC2oZy8gI8HP1gNzWm66RWGSCZMEyvCbBSJxwQn7wNL00St109GTB"
      }
    },
    providers: {
      "up-online": {
        links: {
          home: "http://up.education",
          terms:
            "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
        },
        logo: "/providers/up-online/logo.svg",
        welcomeBg: "/providers/up-online/welcomeBg.jpg",
        logoBg: "#FFF",
        component: {
          footer: {
            showUpLogo: true
          }
        }
      },
      soe: {
        groups: ["avetmiss"],
        component: {
          footer: {
            showUpLogo: false
          },
          TermsAndConditions: {
            requiredPageConfirmations: [
              "agreementPage",
            ]
          },
          Disability: {
            excludedTypes: ["Physical/Mobility", "Support services, equipment and facilities needed", "Multiple Disabilities"]
          },
          Summary: {
            pages: ["formSummary", "summaryInfo"]
          },
          Checkout: {
            hasZipDirect: true
          },
          Optional: {
            exclude: [
              "Checkout.installmentMethod", "Summary.providerInfo"
            ]
          }
        },
        links: {
          home: "https://www.swinburne.edu.au/",
          terms: "https://www.swinburne.edu.au/privacy/",
          privacy: "https://www.swinburne.edu.au/privacy/",
          policies: "https://www.swinburneopen.edu.au/policies",
          faq: "https://swinburne-openeducation-web-uat.azurewebsites.net/faqs",
          paymentPlan: "https://www.swinburneopen.edu.au/payment-plan-terms-and-conditions"
        },
        welcomeBg: "/providers/soe/welcomeBg.jpg",
        logoBg: "#FFF"
      },
      iahealth: {
        groups: ["avetmiss"],
        googleAnalytics: {
          googleAnalyticsId: "GTM-5C58GZQ"
        },
        component: {
          storeCardDetails: true,
          footer: {
            showUpLogo: false
          },
          Optional: {
            include: ["SignUp.postcode"],
            exclude: [
              "Address.postcode",
              "Checkout.paymentPlanStartDate",
              "Checkout.installmentMethod",
              "Checkout.paymentPlanFrequency",
              "OnlineEnrolmentForm.step.paymentPlan",
              "OnlineEnrolmentForm.step.termsAndConditions.review"
            ]
          },
          Checkout: {
            overridePaymentPlanFrequency: "Fortnightly",
            overridePaymentInstallmentMethod: "Credit Card",
            planPaymentProvider: "AIPC Manual",
            overridePaymentDate: "Today",
            noAlternativePaymentMethod: false,
            hideTotalAndDiscount: true
          },
          TermsAndConditions: {
            requiredPageConfirmations: [
              "agreementPage",
            ]
          },
          Disability: {
            excludedTypes: ["Neurological condition"]
          },
          Summary: {
            pages: ["formSummary", "summaryInfo"]
          }
        },
        links: {
          home: "https://www.iah.edu.au/",
          terms: "https://www.iah.edu.au/privacy-statement",
          privacy: "https://www.iah.edu.au/privacy-statement",
          policies: "https://www.iah.edu.au/privacy-statement"
        },
        logo: "/providers/iahealth/iah-logo-w-wordmarkl.svg",
        providerFavicon: "/providers/iahealth/iah-favicon.svg",
        logoBg: "#FFF"
      },
      colab: {
        groups: ["avetmiss"],
        googleAnalytics: {
          googleAnalyticsId: "GTM-PFP7TKRR"
        },
        component: {
          isZipEnabled: true,
          footer: {
            showUpLogo: false
          },
          TermsAndConditions: {
            requiredPageConfirmations: [
              "agreementPage",
            ]
          },
          Optional: {
            exclude: [
              "OnlineEnrolmentForm.step.paymentPlan",
              "OnlineEnrolmentForm.step.termsAndConditions.review"
            ]
          },
          Disability: {
            excludedTypes: ["Physical/Mobility", "Support services, equipment and facilities needed", "Multiple Disabilities"]
          },
          Summary: {
            pages: ["formSummary"]
          },
          SignUp: {
            validatePhoneNumber: false
          },
          Complete: {
            showCompletePageImage: false
          }
        },
        links: {
          home: "https://www.colab.edu.au/",
          terms: "https://www.colab.edu.au/terms-and-conditions",
          privacy: "https://www.colab.edu.au/terms-and-conditions",
          policies: "https://www.colab.edu.au/terms-and-conditions"
        },
        logo: "/providers/colab/ColabLogo.svg",
        providerFavicon: "/providers/colab/ColabFavicon.png",
        logoBg: "#FFF"
      }
    },

    configGroups: {
      default: {
        // All providers default to these
        links: {},
        component: {
          addressAutoComplete: true,
          Optional: {
            exclude: [
              "LanguageAndCulture.indigenousIdentifier",
              "StudentDetails.dateOfBirth",
              "SignUp.postcode"
            ]
          },
          SignUp: {
            leadSource: "Online Application"
          }
        }
      },
      // Providers may selectively opt into these
      avetmiss: {
        avetmiss: true,
        links: {
          usi: "https://portal.usi.gov.au/student/TermsAndConditions?ReturnUrl=%252fstudent%252fUsi%252fForgotten%252fSuccess"
        }
      },
      // Groups for specific journeys within providers
      "new-zealand": {
        salesJourneyCRMId: "809730001",
        component: {
          Address: {
            addressCountry: "NZ"
          },
          Checkout: {
            checkPhoneCountry: "NZ",
            fullPaymentProvider: "Credit Card NZ", // payment provider to use for 'Full Payment' discounts to apply
            planPaymentProvider: "Debit Success NZ" // payment provider to use for 'Full Payment' discounts to apply
          },
          SignUp: {
            checkPhoneCountry: "NZ",
            disallowMinors: true,
            leadSource: "Website - SOE-NZ"
          },
          USI: {
            overrideValue: "INTOFF"
          },
          Optional: {
            include: ["StudentDetails.dateOfBirth"],
            exclude: [
              "Summary.workPlacement",
              "Summary.studentServices",
              "Agreement.workPlacement"
            ]
          }
        },
        logo: "/providers/soe/soe-nz/logo.svg"
      },
      australia: {
        salesJourneyCRMId: "809730000",
        component: {
          Address: {
            addressCountry: "AU"
          },
          Checkout: {
            checkPhoneCountry: "AU",
            fullPaymentProvider: "Credit Card AU", // payment provider to use for 'Full Payment' discounts to apply
            planPaymentProvider: "Stripe Subscription AU" // payment provider to use for 'Full Payment' discounts to apply
          },
          Optional: { include: ["LanguageAndCulture.indigenousIdentifier"] },
          SignUp: {
            checkPhoneCountry: "AU",
            disallowMinors: true
            // leadSource: "Website - SOE" - keep default until release
          }
        },
        logo: "/providers/soe/soe-au/logo.svg"
      }
    }
  },

  // Use these values if stage is not specified
  unknown: {},

  // Stage specific
  local: {
    upApi: {
      baseUri: upApiOverride || "http://localhost:3030/"
    },
    global: {
      stripe: {
        mockPayment: false
      },
      zip: {
        tenantKey: "37639705-ee21-41ed-af4d-8c4d3694ad31"
      },
      google: {
        apiKey: "AIzaSyCuhFbRG6PI2lAU7u0WNSWjxyZHqQPJN48"
      }
    },
    providers: {
      soe: {
        stripePublishableKey: "pk_test_51IVl5KLkJsdAHvpwrG5GN2E2aRbRDBlmcReHAmyBbwu1L3wcNzRc8IcWaYZyDqcYSoxhTKBbg8vlTbdkOLmZFQQU00pRNXr2Jb",
        stripeProductId: "prod_JjA6WzTkwROPj7"
      }
    }
  },

  development: {
    analytics: {
      debug: 1
    },
    global: {
      googleAnalytics: {
        googleAnalyticsId: "GTM-PRBCKLS",
      },
      zip: {
        tenantKey: "37639705-ee21-41ed-af4d-8c4d3694ad31"
      },
    },
    providers: {
      iahealth: {
        googleAnalytics: {
          googleAnalyticsId: "GTM-5C58GZQ"
        }
      },
      colab: {
        googleAnalytics: {
          googleAnalyticsId: "GTM-PFP7TKRR"
        }
      },
      soe: {
        stripePublishableKey: "pk_test_51IVl5KLkJsdAHvpwrG5GN2E2aRbRDBlmcReHAmyBbwu1L3wcNzRc8IcWaYZyDqcYSoxhTKBbg8vlTbdkOLmZFQQU00pRNXr2Jb",
        stripeProductId: "prod_JjA6WzTkwROPj7"
      }
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/" //"https://up-mock-api.azurewebsites.net/"
    }
  },

  test: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
    },
    global: {
      zip: {
        tenantKey: "37639705-ee21-41ed-af4d-8c4d3694ad31"
      },
      links: {}
    },
    providers: {
      soe: {
        stripePublishableKey: "pk_test_51ICGIGIU968z20uKn3plzqIEvcH3RqIXMowWiu0kzpFaFNC2oZy8gI8HP1gNzWm66RWGSCZMEyvCbBSJxwQn7wNL00St109GTB",
        stripeProductId: "prod_J3c529DEnbfyYP"
      }
    }
  },
  uat: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
    },
    global: {
      stripe: {
        publicToken:
          "pk_test_51IXtu1HcFAuInMxE5uI2huIvekZR6IWIk7HuVR3latrj8j3y4H4i6gxAPpa0kYl2kujsrlUUJQGuRjj7ugvQhHYl008Z1EDnov"
      },
      zip: {
        tenantKey: "37639705-ee21-41ed-af4d-8c4d3694ad31"
      },
      links: {}
    },
    providers: {
      soe: {
        stripePublishableKey: "pk_test_51IXtu1HcFAuInMxE5uI2huIvekZR6IWIk7HuVR3latrj8j3y4H4i6gxAPpa0kYl2kujsrlUUJQGuRjj7ugvQhHYl008Z1EDnov",
        stripeProductId: "prod_R5n8qUWitP4A4g"
      }
    }
  },
  production: {
    upApi: {
      baseUri: "https://up-int-apim-prod.azure-api.net/"
    },
    global: {
      stripe: {
        publicToken:
          "pk_live_51IVl56F0Mq5kHxvqdV9jFKx1n0wiqgSxME76uiIKXon1YKBi3PtU9mHG01GnnweBXioG9Z9n5z6o5Fr5DGbiD1gv00kJZB9QAu"
      },
      zip: {
        tenantKey: "c7f6868d-b605-4d72-99ac-68bfef8f1f85"
      },
      links: {}
    },
    providers: {
      soe: {
        stripePublishableKey: "pk_live_51IVl56F0Mq5kHxvqdV9jFKx1n0wiqgSxME76uiIKXon1YKBi3PtU9mHG01GnnweBXioG9Z9n5z6o5Fr5DGbiD1gv00kJZB9QAu",
        stripeProductId: "prod_Qz3HimTY1etd1D",
        links: {
          faq: "https://swinburne-openeducation-web.azurewebsites.net/faqs"
        }
      }
    }
  }
};

function customizer(obj, src) {
  if (isArray(obj)) return union(obj, src); //merge arrays (e.g. exclude,include) as sets
}

// Create a per-stage config
const stageConfig = lodash.mergeWith({}, data["default"], data[stage], customizer);

// Select provider based on matching host (overridable with REACT_APP_JOURNEY)
const [journeyName, providerJourney] = Object.entries(hostMap).find(
  ([journeyName, { match }], i) => process.env.REACT_APP_JOURNEY === journeyName || window.location.host.match(match) // overide with env value, or find matching host
) || [null, {}];

const { provider, groups: journeyGroups } = providerJourney;

// Provider config to merge in
const providerConfig = provider ? stageConfig.providers[provider] : {};

// Shared groups to merge in (specified per-provider and per-journey)
const groups = ["default", ...(providerConfig.groups || []), ...(journeyGroups || [])];

// Merge in groups
const baseConfig = lodash.merge(
  stageConfig,
  {
    global: {
      ...lodash.mergeWith.apply(lodash, [
        ...groups.map((group) => stageConfig.configGroups[group] || {}),
        providerConfig,
        customizer
      ]),
      provider,
      journey: journeyName,
      groups: groups
    }
  },
  customizer
);

// Merge in development persisted settings
const config = isDev ? lodash.mergeWith({}, baseConfig, loadClientConfig(clientConfigKey), customizer) : baseConfig;
console.groupCollapsed(`Configuration for Stage: ${stage} Provider: ${provider}, Journey: ${journeyName}`);
console.dir(config);
console.groupEnd();
export default config;
